<template>
  <div>
    <b-card>
      <sale-report-public-filter
        :action-filter="filterAction"
        :action-methods="searchAction"
        :reset-action="resetAction"
      />
    </b-card>
    <b-row v-if="loadingReport">
      <b-col cols="12">
        <Loading />
      </b-col>
    </b-row>
    <template v-else>
      <b-card
        v-if="reportData.length > 0"
        no-body
      >
        <b-table
          responsive="sm"
          :fields="fields"
          :items="reportData"
          striped
          hover
        >
          <template #cell(order_number)="data">
            <b-link
              :to="'/sale_orderforms/view/' + data.item.order_number"
              target="_blank"
              :disabled="data.item.status === '1' ? false : true"
            >
              {{ data.item.order_number }}
            </b-link>
            <div>
              <b-badge :variant="data.item.status === '1' ? 'success' : 'secondary'">
                <div v-if="data.item.status === '0'">
                  Onay Bekliyor
                </div>
                <div v-if="data.item.status === '1'">
                  Onaylandı
                </div>
                <div v-if="data.item.status === '2'">
                  Reddedildi
                </div>
                <div v-if="data.item.status === '3'">
                  Hazırlanıyor
                </div>
                <div v-if="data.item.status === '4'">
                  Aksesuar İçin Gönderildi
                </div>
              </b-badge>
            </div>
          </template>
          <template #cell(customer_name)="data">
            <div v-if="data.item.customer_name">
              {{ data.item.customer_name }}
              <div v-if="data.item.company_name">
                <small class="text-primary">{{ data.item.company_name }}</small>
              </div>
            </div>
            <div v-else>
              <small class="text-primary">{{ data.item.company_name }}</small>
            </div>
          </template>
          <template #cell(brand)="data">
            {{ data.item.brand }} {{ data.item.model }}
            <div>
              <small class="text-warning">{{ data.item.hardware }}</small>
            </div>
            <div>
              <small class="text-primary">{{ data.item.chasis }}</small>
            </div>
          </template>
          <template #cell(fatura_no)="data">
            {{ data.item.fatura_tarihi ? moment(data.item.fatura_tarihi).format('DD.MM.YYYY') : '-' }}
            <div>
              <small class="text-warning">{{ data.item.fatura_no }}</small>
            </div>
          </template>
          <template #cell(created)="data">
            {{ moment(data.item.created).format('DD.MM.YYYY') }}
            <div v-if="data.item.invoice">
              <small class="text-success">
                Bağlantı: {{ moment(data.item.fatura_tarihi).diff(moment(data.item.created),'days') }} gün.
              </small>
            </div>
            <div v-else>
              <small class="text-warning">
                Bağlantı: {{ moment().diff(moment(data.item.created),'days') }} gün.
              </small>
            </div>
            <div>
              <small class="text-primary">{{ data.item.username }}</small>
            </div>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
      <b-alert
        v-else
        show
        variant="info"
        class=""
      >
        <div class="alert-body text-center">
          <feather-icon icon="InfoIcon" />
          Gösterilecek veri yok.
        </div>
      </b-alert>
    </template>
  </div>
</template>
<script>
import {
  BAlert, BCard, BCol, BRow, BTable, BLink, BCardFooter, BPagination, BBadge,
} from 'bootstrap-vue'
import SaleReportPublicFilter from '@/views/Reports/Sales/SaleReport/Components/FilterCard.vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'SaleReport',
  components: {
    BBadge,
    BPagination,
    BCardFooter,
    BTable,
    BAlert,
    Loading,
    BRow,
    BCol,
    SaleReportPublicFilter,
    BCard,
    BLink,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      dataQuery: {
        limit: 25,
        start: 0,
      },
      fields: [
        {
          key: 'order_number',
          label: 'Föy No',
          thClass: 'text-left font-small-2 ',
          tdClass: 'font-small-2',
        },
        {
          key: 'customer_name',
          label: 'Müşteri',
          thClass: ' text-left font-small-2 ',
          tdClass: '  font-small-2',
        },
        {
          key: 'brand',
          label: 'Araç Bilgisi',
          thClass: ' text-left font-small-2 ',
          tdClass: '  font-small-2',
        },
        {
          key: 'fatura_no',
          label: 'Fatura',
          thClass: ' text-left font-small-2 ',
          tdClass: '  font-small-2',
        },
        {
          key: 'created',
          label: 'Bağlantı',
          thClass: 'text-left font-small-2 ',
          tdClass: 'font-small-2 text-nowrap',
        },
      ],
    }
  },
  computed: {
    reportData() {
      return this.$store.getters['saleReport/getReport']
    },
    dataCount() {
      return this.$store.getters['saleReport/getReportDataCount']
    },
    filterData() {
      return this.$store.getters['saleReport/getFilterData']
    },
    loadingReport() {
      return this.$store.getters['saleReport/getLoading']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getReport()
      }
    },
    getReport() {
      this.$store.dispatch('saleReport/report', this.dataQuery)
    },
    filterAction() {
      if (this.filterData.id_com_brand) {
        this.dataQuery.id_com_brand = this.filterData.id_com_brand
      }
      if (this.filterData.id_com_model) {
        this.dataQuery.id_com_model = this.filterData.id_com_model
      }
      if (this.filterData.id_com_user) {
        this.dataQuery.id_com_user = this.filterData.id_com_user
      }
      if (this.filterData.keyword) {
        this.dataQuery.keyword = this.filterData.keyword
      }
      if (this.filterData.status) {
        this.dataQuery.status = this.filterData.status
      }
      if (this.filterData.sdate) {
        this.dataQuery.sdate = this.filterData.sdate
      }
      if (this.filterData.edate) {
        this.dataQuery.edate = this.filterData.edate
      }
      this.getReport()
    },
    resetAction() {
      this.$store.commit('saleReport/SET_RESET_FILTER_DATA')
      this.dataQuery = {
        limit: 25,
        start: 0,
      }
      this.filterAction()
    },
    searchAction() {
      this.dataQuery.keyword = this.filterData.keyword
      this.getReport()
    },
  },

}
</script>
