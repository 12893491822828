<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          label="Hızlı Arama"
          label-for="keyword"
        >
          <b-input-group>
            <b-form-input
              id="keyword"
              v-model="filterData.keyword"
              placeholder="Müşteri Adı, Firma Adı, Şasi No..."
              @keydown.enter="searchAction"
            />
            <b-input-group-append>
              <b-button
                v-if="filterData.keyword"
                variant="warning"
                @click="resetActionSearch"
              >
                <FeatherIcon icon="XCircleIcon" />
              </b-button>
              <b-button
                variant="primary"
                @click="searchAction"
              ><FeatherIcon icon="SearchIcon" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="filterData.detailSearch"
          class="mt-2"
          name="check-button"
          style="padding-top: 5px"
          switch
          inline
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="filterData.detailSearch">
      <b-col
        xs="12"
        md="4"
      >
        <b-form-group
          label="Marka"
          label-for="id_com_brand"
        >
          <v-select
            id="id_com_brand"
            v-model="filterData.id_com_brand"
            placeholder="Marka"
            :options="brands"
            label="title"
            :reduce="brand => brand.id"
            @input="getModels(filterData.id_com_brand)"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="4"
      >
        <b-form-group
          label="Model"
          label-for="id_com_model"
        >
          <v-select
            id="id_com_model"
            v-model="filterData.id_com_model"
            placeholder="Model"
            :options="models"
            label="title"
            :reduce="model => model.id"
          >
            <template v-slot:option="option">
              <div v-if="option.brand">
                {{ option.title }}
                <div>
                  <small class="text-warning">{{ option.brand }}</small>
                </div>
              </div>
              <div v-else>
                {{ option.title }}
              </div>
            </template>
            <template v-slot:selected-option="option">
              <div v-if="option.brand">
                {{ option.title }}
                <small class="text-warning">{{ option.brand }}</small>
              </div>
              <div v-else>
                {{ option.title }}
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="4"
      >
        <b-form-group
          label="Danışman"
          label-for="id_com_user"
        >
          <v-select
            id="id_com_user"
            v-model="filterData.id_com_user"
            placeholder="Danışman"
            :options="users"
            label="title"
            :reduce="user => user.id"
          >
            <template v-slot:option="option">
              <div v-if="option.brand">
                {{ option.title }}
                <div>
                  <small class="text-warning">{{ option.brand }}</small>
                </div>
                <div v-if="option.user_type">
                  <small class="text-primary">{{ option.user_type }}</small>
                </div>
              </div>
              <div v-else>
                {{ option.title }}
              </div>
            </template>
            <template v-slot:selected-option="option">
              <div v-if="option.brand">
                {{ option.title }}
                <small class="text-warning">{{ option.brand }}</small>
                <div v-if="option.user_type">
                  <small class="text-primary">{{ option.user_type }}</small>
                </div>
              </div>
              <div v-else>
                {{ option.title }}
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="4"
      >
        <b-form-group
          label="Durum"
          label-for="status"
        >
          <v-select
            id="status"
            v-model="filterData.status"
            placeholder="Durum"
            :options="filterData.statuses"
            label="title"
            :reduce="status => status.id"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="4"
      >
        <b-form-group
          label="Başlangıç Tarihi"
          label-for="sdate"
        >
          <b-form-datepicker
            id="sdate"
            v-model="filterData.sdate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="4"
      >
        <b-form-group
          label="Bitiş Tarihi"
          label-for="edate"
        >
          <b-form-datepicker
            id="edate"
            v-model="filterData.edate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-alert
          v-if="filterData.sdate ||filterData.edate"
          show
          variant="info"
        >
          <div class="alert-body text-center">
            <feather-icon icon="InfoIcon" />
            Tarih filtre alanları fatura tarihine göre filtrelemektedir. Faturası olmayan bağlantıda olan kayıtlar gözükmeyecektir.
          </div>
        </b-alert>
      </b-col>
      <b-col cols="12">
        <b-button
          variant="primary"
          @click="filterAction"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
        <b-button
          v-if="filterData.id_com_model !== null || filterData.id_com_brand !== null || filterData.id_com_user !== null || filterData.status !== null || filterData.sdate !== null || filterData.edate !== null"
          class="ml-1"
          variant="warning"
          @click="resetAction"
        >
          <FeatherIcon icon="XCircleIcon" />
          Sıfırla
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BFormCheckbox, BInputGroup, BInputGroupAppend, BFormDatepicker, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'SaleReportPublicFilter',
  components: {
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    actionMethods: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    actionFilter: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    resetAction: {
      type: Function,
    },
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    models() {
      return this.$store.getters['models/getModels']
    },
    filterData() {
      return this.$store.getters['saleReport/getFilterData']
    },
  },
  created() {
    this.getModels()
    this.getBrands()
    this.getUsers()
  },
  methods: {
    getModels(brand = null) {
      if (brand) {
        this.filterData.id_com_model = null
        this.$store.dispatch('models/modelsList', {
          select: ['com_model.id AS id', 'com_model.title AS title', 'com_brand.name as brand'],
          where: {
            'com_model.id_com_brand': brand,
          },
        })
      } else {
        this.$store.dispatch('models/modelsList', {
          select: ['com_model.id AS id', 'com_model.title AS title', 'com_brand.name as brand'],
        })
      }
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: ['com_user.id AS id', 'com_user.name AS title', 'com_brand.name as brand', 'com_user_type.title as user_type'],
        where: {
          'com_user.status': 1,
          'com_user.id_com_department': 2,
        },
      })
    },
    searchAction() {
      this.actionMethods()
    },
    filterAction() {
      this.actionFilter(this.filterData)
    },
    resetActionSearch() {
      this.filterData.keyword = null
      this.searchAction()
    },
  },
}
</script>
<style scoped></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
